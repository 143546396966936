import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>QUEUEFY.ME</h1>
        <p>
          Integrate a queue management service to your endpoint
        </p>
        <a
          className="App-link"
          href="https://queuefy.me/integrations"
          target="_blank"
          rel="noopener noreferrer"
        >
          Start
        </a>
      </header>
    </div>
  );
}

export default App;
